@import '../../styles/variable';
@import '../../styles/mixins';
.contact{
    grid-column: content-start/full-end;
    display: grid;
    grid-auto-rows: min-content;
    padding: 5rem;
    &__description{
        grid-column: 1/2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &>*:not(:last-child){
            margin-bottom: 1rem;
        }
        &-subheading{
            color: $stroke;
        }
        &-heading{
            display: flex;
            flex-direction: column;
        }
        &-paragraph{
        color: $text-light;
        }
    }

    &__social{
        margin-top: 4rem;
        &>*:not(:last-child){
            margin-bottom: 1rem;
        }
        &-item{
            display: flex;
            align-items: center;
            color: $text-light;
            letter-spacing: .04rem;
            &>*:not(:last-child){
                margin-right: 1rem;
            }
            span{
                a{
                    &:link,
                    &:visited,
                    &:active{
                        text-decoration: none;
                        color: $text-light;
                    }
                    &:hover{
                        color: $stroke;
                    }
                }
            }
            svg{
                width: 2.5rem;
                height: 2.5rem;
                stroke: $stroke;
            }
        }
    }
    &__svg{
        grid-row: 1/3;
        grid-column: 2/3;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__form{
        grid-column: 2/3;
        &>*:not(:last-child){
            margin-bottom: 1.25rem;
        }
        &--inputContainer{
            width: 80%;
            &-input{
                width: 100%;
                padding:1rem 1.25rem;
                outline:none;
                border: 1px solid $primary;
                background-color: $input;
                border-radius: 1rem;
            }
            textArea{
                resize: none;
            }
        }
        &--button{
            margin-top: 4rem;
            width: 40%;
            button{
                width: 100%;
                outline: none;
                border: 0;
                background-color: $stroke;
                padding: 1.5rem 1.75rem;
                cursor: pointer;
                @include flexCenter;
                border-radius: 2rem;
                color: $white;
                transition: transform .2s;
                letter-spacing: .04rem;
                &,&:link,&:visited,&:active{
                    text-decoration: none;
                    color: $white;
                }
                &:hover{
                    transform: translateY(-.2rem);
                }
                        }
                    }
                }
    @media screen and (min-width:1400px) {
        padding: 1rem 5rem;
    }
    @media screen and (max-width:1000px) {
        &__description{
            &-paragraph{
                display: flex;
                flex-direction: column;
                &>*:not(:last-child){
                    margin-bottom: .5rem;
                }
            }
        }
        &__social{
            &-item{
                &--text{
                    margin: 1.75rem 0;
                    display: flex;
                    flex-direction: column;
                    &>*:not(:last-child){
                        margin-bottom: .5rem;
                    }
                }
            }
        }
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
    }
    @media screen and (max-width:600px) {
        padding: 5rem 2rem 5rem 5rem;
        &__social{
            margin-top: 2rem;
        }
        &__svg{
            svg{
                width: 40vw;
            }
        }
        &__form{
            padding-right: 3rem;
            margin-top: 4rem;
            grid-column: 1/3;
            &--inputContainer{
                width: 100%;
            }
            &--button{
                margin-top: 4rem;
                width: 30%;
            }
        }
    }
    @media screen and (max-width:400px) {
        &__form{
            &--button{
                width: 40%;
            }
        }
    }
}

@keyframes contactAnim {
    from{
        opacity: 0;
        transform: translateY(-6rem);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}