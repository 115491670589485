@import '../../styles/variable';
.footer{
    grid-column: content-start/full-end;
    background-color: $secondary;
    padding: 2rem 5rem;
    &__copyright{
        
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
    }
}