@import './styles/variable';
.app{
    height: 100%;
    display: grid;
    grid-template-columns: [full-start sidebar-start] minmax(8rem, 10rem) 
        [content-start sidebar-end] repeat(12,[col-start] minmax(min-content,10rem) [col-end]) [content-end] 
        minmax(6rem,1fr) [full-end];
    grid-auto-rows: min-content;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    section{
        scroll-snap-align: start;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    @media screen and (max-width:1000px) {
        scroll-snap-type: initial;
        overflow-y: initial;
        section{
            scroll-snap-align: initial;
            height: initial;
        }
    }
}
.stack{
    grid-column: content-start/full-end;
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
    }
}