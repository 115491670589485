.notSure{
    grid-column: content-start/full-end;
    // padding: 6rem 4rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex:1;
    &__heading{

    }
    &__text{
        line-height: 2rem;
        margin:2rem 0;
        width: 50%;
        text-align: center;
    }
    &__button{
        width: 15rem;
        margin-top: 2rem;
    }
    @media screen and (min-width:1000px) and (max-width:1200px) {
        padding: 2rem 4rem;
        &__text{
            width: 70%;
        }
    }
    @media screen and (max-width:1000px) {
        padding: 6rem 4rem;
    }
    @media screen and (max-width:800px) {
        grid-column: full-start / full-end;
    }
}