@import '../../styles/variable';
.beforeLeave{
    grid-column: content-start/full-end;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5rem;
    flex: 1;
    &__svg{
        position: absolute;
        right: -20%;
        width: 50vw;
        svg{
            width: 100%;
            height: auto;
            path{
                fill: $stroke;
            }
        }
        @media screen and (max-width:600px) {
            width: 60vw;
            
        }
    }
    &>*:not(:first-child){
        margin: 2rem 0;
    }
    &__heading{
        display: flex;
        flex-direction: column;
        &-head{
            span{
                color: $stroke;
            }
        }
    }
    &__subheading{
        color: $text-light; 
        &>*:last-child{
            &>*:last-child{
                color: $stroke;
                border-bottom: 2px solid $stroke;
            }
        }
        @media screen and (max-width:600px) {
            display: flex;
            flex-direction: column;
            &>*:not(:last-child){
                margin-bottom: .5rem;
            }
        }
    }
    &__button{
        width: 15rem;
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
    }
}
@keyframes beforeLeaveAnim {
    from{
        opacity: 0;
        transform: translateY(-6rem);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}