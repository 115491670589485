.paragraph{
    font-size: 1.25rem;
    font-family: 'AcuminPro-regular';
    @media screen and (max-width:800px) {
        font-size: 1.2rem;
    }
    @media screen and (max-width:500px) {
        font-size: 1rem;
    }
}
.paragraph-bold{
    font-size: 1.25rem;
    font-family: 'AcuminPro-bold';
}
.heading-1{
    font-size: 4rem;
    font-family: 'AcuminPro-bold';
    @media screen and (max-width:1200px) {
        font-size: 3.75rem;
    }
    @media screen and (max-width:1000px) {
        font-size: 3.25rem;
    }
    @media screen and (max-width:900px) {
        font-size: 3rem;
    }
    @media screen and (max-width:700px) {
        font-size: 2.5rem;
    }
    @media screen and (max-width:500px) {
        font-size: 2.25rem;
    }
}
.heading-2{
    font-size: 2.5rem;
    font-family: 'AcuminPro-regular';
}
.heading-4{
    font-size: 1.4rem;
    font-family: 'AcuminPro-regular';
    @media screen and (max-width:800px) {
        font-size: 1.25rem;
    }
    @media screen and (max-width:500px) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
.subheading{
    font-size: 1.6rem;
    font-family: 'AcuminPro-bold';
}
.tertiary-heading{
    font-size: 1.4rem;
    font-family: 'AcuminPro-bold';
}
.quote_heading{
    font-size: 3.25rem;
    font-family: 'AcuminPro-bold';
}
.subheading-light{
    font-size: 1.6rem;
    font-family: 'AcuminPro-regular';
}