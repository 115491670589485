@import '../../styles/variable';
@import '../../styles/mixins';
.button{
    background-color: $stroke;
    padding: 1.5rem 1.75rem;
    cursor: pointer;
    @include flexCenter;
    border-radius: 2rem;
    color: $white;
    transition: transform .2s;
    letter-spacing: .04rem;
    &,&:link,&:visited,&:active{
        text-decoration: none;
        color: $white;
    }
    &:hover{
        transform: translateY(-.2rem);
    }
}