@import '../../styles/variable';
.hotOffers{
    grid-column: content-start/full-end;
    background-color: $primary;
    padding: 5rem 5rem 8rem 5rem;
    &__heading{
        
    }
    &__images{
        margin-top: 4rem;
        display: flex;
        &>*:not(:last-child){
            margin-right: 4rem;
        }
        &-img{
            text-align: center;
            img{
                width: 17vw;
                height: auto;
                object-fit: cover;
                @media screen and (max-width:1000px) {
                    width: 15vw;
                    height: auto;
                }
                @media screen and (max-width:900px) {
                    width: 14vw;
                    height:auto;
                }
                @media screen and (max-width:800px) {
                    width: 16vw;
                    height: auto;
                }
                @media screen and (max-width:800px) {
                    width: 16vw;
                    height: auto;
                }
            }
            &--subheading{
                margin: 2rem 0 .5rem 0;
            }
            &--description{
                color: $text-light;
                letter-spacing: .08rem;
                font-size: 1.25rem;
            }
        }
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
        padding: 2rem;
        padding-bottom: 8rem;
    }
}
@keyframes hotOffersOddAnim {
    from{
        opacity: 0;
        transform: translateY(-5rem);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes hotOffersEvenAnim {
    from{
        opacity: 0;
        transform: translateY(5rem);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
    
}