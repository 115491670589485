@import '../../styles/variable';
.heroSection{
    height: calc(100vh - 8rem);
    grid-column: content-start / full-end;
    background-color: $secondary;
    display: flex;
    &__left{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5rem;
        &-heading{
            display: flex;
            flex-direction: column;
            &>*:not(:last-child){
                margin-bottom: 1rem;
            }
        }
        &-subheading{
            width: 75%;
            margin-top: 2rem;
            color: $text-light;
        }
        &-contact{
            width: 10rem;
            margin-top: 6rem;
        }
    }
    &__right{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        &-img{
            width: 100%;
            height: 70%;
            background-image: url(../../assets/HeroSection.webp);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 2rem 0 0 2rem;
            @media screen and (max-width:1200px) {
                height: 60%;
            }
            @media screen and (max-width:1000px) {
                height: 50%;
            }
            @media screen and (max-width:800px) {
                height: 40%;
            }
            @media screen and (max-width:500px) {
                height: 35%;
            }
        }
        @media screen and (max-width:1000px) {
            width: 40%;
            height: 90%;
        }
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
        flex-direction: column;
        &__right{
            width: 100%;
            height: 100%;
            &-img{
                height: 100%;
                border-radius: 0;
            }
        }
    }
}
@keyframes heroSectionAnim {
    from{
        opacity: 0;
        transform: translateY(-6rem);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}