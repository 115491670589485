.whyClient{
    grid-column: content-start/full-end;
    padding:2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__boxes{
        margin: 4rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
        &>*:not(:last-child){
            margin-right: 5rem;
        }
        &-box{
            display: flex;
            flex-direction: column;
            &--img{
                width: 20rem;
                height: auto;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            &--content{
                text-align: center;
            }
        }
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
        &__boxes{
            &-box{
                &--img{
                    width: 15rem;
                }
            }
        }
    }
    @media screen and (max-width:600px) {
        grid-column: full-start/full-end;
        &__boxes{
            flex-direction: column;
            align-items: center;
            &>*:not(:last-child){
                margin-right: 0;
                margin-bottom: 2rem;
            }
            &-box{
                &--img{
                    width: 18rem;
                }
            }
        }
    }
}