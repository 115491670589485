@import '../../../styles/variable';
.headerBottom{
    grid-column: sidebar-start/sidebar-end;
    position:fixed;
    height: 100vh;
    width: 10rem;
    text-transform: uppercase;
    @media screen and (max-width:1000px) {
        padding: 2rem;
    }
    &__pages{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8vh 0;
        &-page{
            cursor: pointer;
            padding:.5rem;
            transform: rotate(-90deg);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &:link,
                &:visited,
                &:hover,
                &:active{
                    text-decoration: none;
                    color: black;
                }
            &:hover{
                color: $stroke;
            }
            &::before{
                content:'';
                height: .2rem;
                width: 100%;
                position: absolute;
                top: -.5rem;
                transition: background-color .5s;
            }
            &:hover::before{
                display: block;
                background-color: $stroke;
            }
        }
    }
    @media screen and (max-width:800px) {
        width: 100%;
        grid-column: full-start/full-end;
        position: relative;
        height: min-content;
        display: flex;
        padding: 2rem 3rem;
        &__pages{
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            &>*:not(:last-child){
                margin-bottom: 0;
                // margin-right: 2rem;
            }
            &-page{
                transform: none;
            }
        }
    }
    @media screen and (max-width:400px) {
        padding: 2rem;
    }
}