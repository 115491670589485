*,*::before,*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
div#root{
    height: 100%;
}
html{
    font-size: 10px;
    height: 100%;
    @media screen and (max-width:900px) {
        font-size: 9.5px;
    }
    @media screen and (max-width:800px) {
        font-size: 9px;
    }
    @media screen and (max-width:600px) {
        font-size: 8px;
    }
}
body{
    font-size: 1.25rem;
    height: 100%;
}
@font-face {
    font-family: AcuminPro-bold;
    src: url("./fonts/Acumin-BdPro.otf") format("opentype");
  }
  @font-face {
    font-family: AcuminPro-regular;
    src: url("./fonts/Acumin-RPro.otf") format("opentype");
  }