@import '../../../styles/variable';
.headerMiddle{
    height: 8rem;
    grid-column: content-start/full-end;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(gray,.2);
    padding: 2rem 10% 2rem 6rem;
    // @media screen and (max-width:1000px) {
    //     padding: 2rem;
    // }
    &__logo{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 12rem;
        }
    }
    &__info{
        display: flex;
        &-content{
            display: flex;
            align-items: center;
            color: rgba(#000,.7);
            svg{
                width: 2rem;
                height: 2rem;
                fill: $secondary;
                stroke: $stroke;
                @media screen and (max-width:1000px) {
                    width: 1.75rem;
                    height: 1.75rem;
                }
            }
            &--text{
                display: flex;
                flex-direction: column;
                letter-spacing: .03rem;
                @media screen and (max-width:1000px) {
                    font-size: 1.2rem;
                }
            }
            &>*:not(:last-child){
                margin-right: 1rem;
            }
        }
        &>*:not(:last-child){
            margin-right: 2rem;
        }
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
        display: none;
    }
}