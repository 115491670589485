@import '../../styles/variable';
.services{
    grid-column: content-start/full-end;
    padding: 5rem;
    padding-bottom: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $primary;
    height: 100vh;
    &__content{
        flex: 1;
        margin-top: 6rem;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(30rem,1fr));
        grid-row-gap: 4rem;
        justify-items: center;
        align-content: center;
        align-items: center;
    }
    @media screen and (min-width:1200px) and (max-width:1400px) {
        &__content{
            grid-template-columns: repeat(auto-fit,minmax(28rem,1fr));
            svg{
                width: 70%;
                height: auto;
            }
        }
    }
    @media screen and (min-width:1000px) and (max-width:1200px) {
        &__content{
            grid-template-columns: repeat(auto-fit,minmax(22rem,1fr));
            svg{
                width: 70%;
                height: auto;
            }
        }
    }
    @media screen and (max-width:1000px){
        &__content{
            grid-template-columns: repeat(auto-fit,minmax(20rem,1fr));
            svg{
                width: 70%;
                height: auto;
            }
        }
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
        padding: 2rem;
        height: auto;
    }
}

@keyframes serviceAnim {
    from{
        opacity: 0;
        transform: translateY(-10rem);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}