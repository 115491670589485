@import './typography';
$white:#fff;
// $primary:#343434;
// $secondary:#ffede1;
// $stroke:#e7ae65;
$text-color:#3b3b43;
$text-light:#c1c1c9;
$primary:#fff2f2;
$secondary:#f8f6ff;
$tertiary:#cbdfe6;
$stroke:#fe7966;
$input:#f2f2f2;