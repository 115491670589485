@import '../../styles/variable';
.howSolve{
    grid-column: content-start/full-end;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondary;
    padding:2rem;
    padding-bottom: .5rem;
    &__heading{
        margin:2rem 0 4rem 0;
    }
    &__cards{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        &>*{
            width: 25%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            background-repeat: no-repeat;
            background-size: cover;
            transition: transform .2s;
        }
        &-card1{
            background-image: linear-gradient(rgba($stroke,.75),rgba($primary,.75)),url(../../assets/appointment.webp);
            margin-right: 4rem;
        }
        &-card2{
            background-image: linear-gradient(rgba($stroke,.75),rgba($primary,.75)),url(../../assets/inoffice.webp);
        }
    }
    &__buy{
        // border-radius: 2rem;
        margin: 4rem 0 .5rem 0;
        width: 100%;
        height: 45vh;
        background-image: linear-gradient(45deg,rgba($stroke,.5),rgba($primary,.5)),url(../../assets/buy.png);
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 5rem;
        &-heading{
            margin-bottom: 1rem;
        }

    }
    @media screen and (max-width:1000px) {
        &__cards{
            &>*{
                width: 35%;
            }
        }
    }
    @media screen and (max-width:800px) {
        grid-column: full-start/full-end;
        &__cards{
            &>*{
                width: 40%;
            }
        }
    }
    @media screen and (max-width:600px) {
        grid-column: full-start/full-end;
        height: 45vh;
        &__cards{
            &>*{
                width: 40%;
                height: 80%;
            }
        }
        &__buy{
            padding-left: 2rem;
            &-subheading{
                display: flex;
                flex-direction: column;
                
            }
        }
    }
    @media screen and (max-width:450px) {
        grid-column: full-start/full-end;
        &__cards{
            &>*{
                width: 45%;
                height: 70%;
            }
        }
    }
}
@keyframes howSolveLeftAnim {
    from{
        opacity: 0;
        transform: translateX(-50%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes howSolveRightAnim {
    from{
        opacity: 0;
        transform: translateX(50%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
    
}

// .howSolve__buy{
//     background-image: url(../../assets/buy.png);
// }